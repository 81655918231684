@media (min-width: 960px) {
	.login-container {
		position: absolute;
		top: 45%;
		left: 50%;
		min-width: 30vw;
		max-width: 100vw;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 960px) {
	.login-container {
		padding-top: 24vh;
		padding-right: 3vw;
		padding-left: 3vw;
		width: "100%";
	}
}
